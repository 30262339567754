/*
==========================================================================
   Aside
==========================================================================
*/

.sidebar {
    /*background-color: $dark;*/
    min-height: 100vh;
    width: $aside-width;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 700;
    transition: none;

    .wrap {
        background-color: $dark;
        min-height: 100vh;
        width: $aside-width;
    }

    .pop-bg {
        display: none;
    }

    .logo {
        height: $navbar-height;
        margin: 0;
        display: flex;
        @include flex-middle;

        >a {
            pointer-events: none;

            &::before {
                content: '';
                display: block;
                width: 101px;
                height: 33.5px;
                background-image: url('/img_logo.png');
                background-size: 100%;
            }
        }
    }

    .nav {

        &:not(.sub-menu) {
            margin: inherit;
            
            .nav-item {
                margin-top: 0;

                &:hover>.nav-link:not(.menu-expanded) {
                    background-color: $min-black;
                    color: $full-white;
                }

                &.active:hover>.nav-link:not(.menu-expanded) {
                    background-color: $primary;
                }
            }
        }

        .nav-item {
            padding: 0;
            transition: background ease-in-out 0.1s;

            .nav-link {
                padding: 0.6rem 1rem;
                font-size: .8125rem;
                font-weight: 700;
                color: $light-white;
                border-radius: 0 !important;
                transition: color ease-in-out 0.1s;

                .menu-title {
                    font-size: inherit;
                    font-weight: inherit;
                }

                i.menu-icon {
                    color: inherit;

                    &::before {
                        position: relative;
                        top: -.09375rem;
                    }
                }

                &[data-toggle]::after {
                    font-family: 'themify';
                    speak: none;
                    font-size: .5rem;
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    content: "\e64b";
                    margin-left: auto;
                }

                &.menu-expanded {
                    background-color: $min-black;
                    color: $full-white;

                    &::after {
                        transform: rotate(180deg);
                    }
                }
            }

            &.active {
                >.nav-link {
                    background-color: $primary;
                    color: $full-white;
                }
            }

            .collapse {
                z-index: auto;
            }
        }
    }

    .nav.sub-menu {
        background-color: $min-black;
        padding: 0;

        .nav-item {

            .nav-link {
                color: $light-white;
                font-size: .8125rem;
                font-weight: 700;
                padding-left: 3.1rem;

                &.active {
                    background-color: $min-black;
                    color: $full-white;
                }
            }

            &::before {
                content: none;
            }
        }
    }
}

.sidebar .nav .nav-item.active>.nav-link .menu-arrow,
.sidebar .nav .nav-item.active>.nav-link .menu-title,
.sidebar .nav .nav-item.active>.nav-link i {
    color: $full-white;
}