/*
==========================================================================
   Layout
==========================================================================
*/
body {
	height: auto;
	min-height: 100%;
}

.page-body-wrapper {
	min-height: 100vh;
	padding-top: 0;
	margin-left: 12.5rem;
	position: relative;
}

.main-panel {
	width: 100%;
	min-height: 100vh;
	padding-top: $navbar-height;
	padding-bottom: $footer-height;
}

.content-wrapper {
	background-color: transparent;
	padding: 1rem;
}

@media (max-width: 991px) {
	.sidebar {
		max-height: auto;
		transition: none;
		display: none;

		.pop-bg {
			transition: none;
		}

		.wrap {
			transform: translateX(-#{$aside-width});
			transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
		}
	}

	.sidebar-offcanvas.active {
		display: block;
		right: auto;
		width: 100%;
		background-color: transparent;

		.pop-bg {
			display: block;
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: $light-black;
		}

		.wrap {
			transform: translateX(0px);
			width: $aside-xl-width;
		}
	}

	.page-body-wrapper {
		margin-left: 0;
	}

	.navbar {
		.navbar-menu-wrapper {

			.navbar-toggler.navbar-toggler-right {
				padding-left: .5rem;
				padding-right: 1.5rem;
			}

			.navbar-nav {

				&.navbar-nav-right {
					margin-left: auto;
				}
			}
		}
	}
}

.footer {
	background-color: transparent;
	border-top: none;
	padding: 0 1rem;
	font-size: $font-size-xs;
	color: $bright-black;
	height: $footer-height;
	display: flex;
	align-items: center;
	font-family: $font-family-serif;
	position: absolute;
	bottom: 0;
}