/*
==========================================================================
   Mixin
==========================================================================
*/

@mixin flex-hor {
    display: flex !important;
    align-items: center !important;
}

@mixin flex-middle {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

@mixin box-style {
    box-shadow: 0 0 0.875rem 0 rgba(33, 37, 41, 0.05);
    border: 1px solid $box-border-color;
}
