/*
==========================================================================
   Responsive
==========================================================================
*/

@media (max-width: 991px) {
   aside.modal {
      width: 100%;
      z-index: 1050;
      display: none;
   }

   .app-contents {
      margin-left: 0;
   }

}