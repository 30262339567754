/*
==========================================================================
   Style :: layout
==========================================================================
*/

.mt-4 {
   margin-top: 1rem !important;
}

.mb-4 {
   margin-bottom: 1rem !important;
}

.ml-4 {
   margin-left: 1rem !important;
}

.mr-4 {
   margin-right: 1rem !important;
}

input {
   height: auto;
}

h2.hello {
   font-size: $font-size-lg;
   font-weight: 300;
}

h3.title {
   font-size: $font-size-sm;
   font-weight: 400;
}

.card {
   @include box-style;
}

.card .card-body {
   padding: 1rem;
}

.card-body-split {
   margin-left: -1rem;
   margin-right: -1rem;
   margin-top: 1rem;
   border-top: 1px solid $title-border-color;
   padding-left: 1rem;
   padding-right: 1rem;
   padding-top: 1rem;
}

.card-wrap {
   margin-left: -1rem;
   margin-right: -1rem;
}

.card-title-wrap {
   margin-top: -1rem;
   margin-left: -1rem;
   margin-right: -1rem;
   padding: 1rem;
   border-bottom: 1px solid $title-border-color;
   margin-bottom: 1rem;
   align-items: center;

   .card-title {
      font-size: $font-size-md;
      color: $dark-black;
      margin: 0;
   }

   .card-sub {
      font-size: $font-size-xs;
      color: $info;
      font-weight: 700;
      align-self: flex-end;
   }
}

.btn {
   padding: .5rem;
}

.btn.btn-sm {
   padding: .25rem .375rem;
   font-size: $font-size-xs;
}

.btn-default {
   background-color: $primary;
   color: $full-white;

   &:hover {
      background-color: $primary-hover;
      color: $full-white;
   }
}

.btn-primary {
   background-color: $primary;
   border-color: $primary;

   &:hover {
      background-color: $primary-hover;
      border-color: $primary-hover;
   }
}


.btn-danger {
   background-color: $danger;
   border-color: $danger;

   &:hover {
      background-color: $danger-hover;
      border-color: $danger-hover;
   }
}

.btn-light {
   background-color: $gray;
   border-color: $gray;

   &:hover {
      background-color: $gray-hover;
      border-color: $gray-hover;
   }
}

.form-control,
select.form-control,
textarea.form-control {
   height: auto;
   padding: .5rem .75rem;
   line-height: 1.25;
   background-color: #fff;
   border: 1px solid $border-color;
   border-radius: .25rem !important;
   outline: none !important;
   color: $dark-black;

   &[readonly] {
      color: $bright-black;
      pointer-events: none;
   }

   &:focus {
      border-color: $primary;
   }
}

.form-file {
   display: flex;

   label {
      flex: 1;
      padding: .5rem .75rem;
      line-height: 1.25;
      background-color: #fff;
      border: 1px solid $border-color;
      border-radius: .25rem;
      color: $light-black;
      width: 100%;
      font-size: $font-size;
      cursor: pointer;
      display: flex;
      height: 2.2rem;

      & + input[type="file"] {
         display: none;
      }

      i {
         flex: none;
         margin-left: auto;
      }
   }
   
   img.profile_preview {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: .25rem;
      margin-right: .375rem;
   }
}

.radiobox {
   margin: 0;
   display: flex;
   align-items: center;

   label {
      margin-bottom: 0;
   }
}

.radio-box {
   @include flex-hor;
   margin: 0;
   margin-right: 1rem;
   cursor: pointer;

   &:last-of-type {
      margin-right: 0;
   }

   input[type="radio"] {
      position: absolute;
      z-index: -1;
      opacity: 0;
      width: 0;
      height: 0;

      & + span:before {
         content: "";
         display: block;
         flex: none;
         background: url('/img_radio_n.png') center/100% no-repeat;
         width: $ui-check-size;
         height: $ui-check-size;
         padding: 0;
         margin: 0;
         margin-right: 3px; 
      }

      &:checked + span:before {
         background: url('/img_radio_s.png') center/100% no-repeat;
      }
   }

   span {
      @include flex-hor;
      font-size: $font-size-sm;
   }
}
.checkbox {

   .checkbox-label {
      @include flex-hor;
      margin: 0;
      //margin-right: 1rem;
      cursor: pointer;

      span {
         @include flex-hor;
         font-size: $font-size-sm;
      }

      input[type="checkbox"] {
         position: absolute;
         z-index: -1;
         opacity: 0;
         width: 0;
         height: 0;

         & + span:before {
            content: "";
            display: block;
            flex: none;
            background: url('/img_check_n.png') center/100% no-repeat;
            width: $ui-check-size;
            height: $ui-check-size;
            padding: 0;
            margin: 0;
            margin-right: 4px; 
         }

         &:checked + span:before {
            background: url('/img_check_s.png') center/100% no-repeat;
         }
      }
   }
}

/*
==========================================================================
   Style :: breadcrumb
==========================================================================
*/

.breadcrumb {
   border: none;
   padding: 0rem;
   margin-top: 0rem;
   background-color: #fff;
   border-radius: 0;
   @include box-style;
   overflow: hidden;

   .breadcrumb-item {
      font-size: $font-size-xs;
      font-weight: 700;
      padding: .5rem .75rem;
      padding-left: 1.3rem;
      margin: 0 !important;
      @include flex-middle;
      position: relative;
      background-color: $light;
      color: $bright-black;

      &::before,
      &::after {
         position: absolute;
         width: 0;
         height: 0;
         content: '';
         border-top: 22px solid transparent;
         border-bottom: 22px solid transparent;
      }

      &::before {
         right: -7px;
         z-index: 3;
         border-left-color: $light;
         border-left-style: solid;
         border-left-width: 8px;
         padding-right: 0;
      }

      &::after {
         right: -8px;
         z-index: 2;
         border-left: 8px solid $border-color;
      }
   }

   li.breadcrumb-item {
      padding-left: 1rem;
   }

   a.breadcrumb-item {

      &:last-of-type {
         background-color: $primary;
         color: $full-white;

         &::before {
            border-left-color: $primary;
         }
      }
   }
}

/*
==========================================================================
   Style :: Static Table
==========================================================================
*/

.ReactTable {
   font-size: $font-size-sm;
   border: none;

   &.card-wrap {
      margin-left: -1rem;
      margin-right: -1rem;
   }

   .rt-thead {

      &.-header {
         border-bottom: 1px solid $table-border-color;
         border-top: 1px solid $table-border-color;
         color: $dark-black;
         background-color: $table-head-bgcolor;
      }

      .rt-th {
         padding: .8125rem .625rem;
      }

      &.-filters {
         border-bottom: 1px solid $table-border-color;

         input {
            height: auto;
            background-color: $table-head-bgcolor;
         }
      }
   }

   .rt-tbody {
      letter-spacing: -0.03125rem;
      border-bottom: 1px solid $table-border-color;

      .rt-tr-group {
         border-bottom: 1px solid $table-border-color;
      }
   }

   .rt-td {
      padding: .8125rem .625rem;

      .edit-wrap {
         display: inline-flex;
         width: 100%;
         justify-content: center;
      }
   }

   .-pagination {
      box-shadow: none;
      border: none;
      padding: 1rem;
      background-color: $table-head-bgcolor;
      border-bottom: 1px solid $table-border-color;

      .-next, .-previous {
         max-width: 120px;
      }

      .-btn {
         background-color: $primary;
         color: $full-white;
         font-weight: 900;

         &[disabled] {
            background-color: $disabled;
         }
      }
   }

   a {
      color: $info;

      &:hover {
         color: $success;
      }
   }

   .ti-pencil-alt::before {
      font-size: 90%;
      margin-right: .25rem;

   }
}

.app-table {
   font-size: $font-size-sm;
   border: none;
   border-top: 1px solid $table-border-color;

   th, td {
      padding: .8125rem .625rem;
      border-bottom: 1px solid $table-border-color;
      border-right: 1px solid $table-border-color;

      a {
         color: $info;
   
         &:hover {
            color: $success;
         }
      }
   
      .ti-pencil-alt::before {
         font-size: 90%;
         margin-right: .25rem;
   
      }
   }

   tr>td:last-of-type,
   tr>th:last-of-type {
      border-right: none;
   }

   th, .th {
      background-color: $table-head-bgcolor;
      font-weight: 700;
   }
}

.board-pagination {
   border-bottom: 1px solid $table-border-color;
   padding: .5rem;
}

ul.tag-wrap {
   @include flex-hor;
   flex-wrap: wrap;
   font-weight: 700;
   margin-top: -0.1875rem;
   margin-bottom: -0.1875rem;

   .tag-item {
      font-size: $font-size-xxs;
      background-color: $darkgray;
      color: $full-white;
      padding: .125rem .5rem;
      flex: none;
      margin: .0625rem .09375rem;

      &.select {
         background-color: $info;
      }
   }

   &.select-mode {

      .tag-item {
         cursor: pointer;
      }
   }
}

/*
==========================================================================
   Style :: contents Tabs
==========================================================================
*/

.tab-wrap {
   width: 100%;
   display: flex;
   z-index: 3;

   .tab-item {
      flex: none;
      font-size: $font-size;
      padding: .75rem 1.25rem;
      margin-bottom: -1px;
      opacity: .5;

      &.active {
         background-color: #fff;
         opacity: 1;
         z-index: 20;
         border: 1px solid $box-border-color;
         border-bottom: 1px solid #fff;
         border-radius: .5rem .5rem 0 0;
      }
   }
}

.nav-tabs {
   width: 100%;
   display: flex;
   z-index: 3;

   .nav-item,
   .nav-item.nav-link {
      flex: none;
      font-size: $font-size;
      padding: .75rem 1.25rem;
      opacity: .5;
      background-color: transparent;
      border: none;
      position: relative;
      top: 1px;

      &.active {
         background-color: #fff;
         opacity: 1;
         z-index: 20;
         border: 1px solid $box-border-color;
         border-bottom: 1px solid #fff;
         border-radius: .5rem .5rem 0 0;
      }
   }
}

/*
==========================================================================
   Style :: Button Bar
==========================================================================
*/

.btnbar {
   margin-top: 1rem;

   .foot-edit {
      display: flex;
      justify-content: space-between;
   }
}

/*
==========================================================================
   Style :: Dialog
==========================================================================
*/

.modal-content {
   border-radius: .75rem;
   border: none;
   box-shadow: 0 0 6px rgba(0,0,0,.15);
}

.modal-header {
   border: none;
   border-top-left-radius: calc(.75rem - 1px);
   border-top-right-radius: calc(.75rem - 1px);

   .modal-title {
      padding-top: 2px;
   }

   @at-root .modal-dialog .modal-header .close {
      margin: -1.25rem -1.25rem -1.25rem auto;
      opacity: .6;

      &:hover {
         color: inherit;
         opacity: 1;
      }
   }
}

.modal-dialog {

   .modal-body {
      padding: 1.5rem 1rem;
      color: $light-black;
   }

   .modal-footer {
      border: none;
      border-bottom-left-radius: calc(.75rem - 1px);
      border-bottom-right-radius: calc(.75rem - 1px);
   }
}

.push.modal-body {
   padding: 0;
   padding-bottom: 1rem;

   .sidebar-widget {
      padding: 0 1rem;
   }
}

/*
==========================================================================
   Style :: Expert
==========================================================================
*/

.expert-profile {
   @include flex-middle;

   img.profile-img {
      width: 180px;
      height: auto;
      object-fit: cover;
      border-radius: 1rem;
      border: 1px solid $min-black;
   }
}

.expert-text {
   padding: .5rem .75rem;
   line-height: 1.25;
   background-color: #fff;
   border:1px solid $border-color;
   border-radius: .25rem !important;
   color: $dark-black;
   outline: none !important;
   min-height: 7.5rem;

   &:focus {
      border-width: 1px;
      border-color: $primary;
   }
}

/*
==========================================================================
   Style :: app push
==========================================================================
*/

.list-wrap {
   display: flex;

   .list-contents {
      flex: 1;
      flex-basis: 18rem;
      height: 20rem;
      overflow-y: auto;
      background-color: $table-head-bgcolor;
      border: 1px solid $table-border-color;
   }

   .put-wrap {
      flex: none;
      width: 7.5rem;
      padding: .25rem;
      @include flex-middle;
      flex-direction: column;

      button {
         margin: .25rem;

         i {
            font-size: inherit;
            margin-right: .1875rem;

            &::before {
               position: relative;
               top: 1px;
            }
         }
      }
   }
}

.list-contents {
   .mail-list {
      padding: 0.75rem;
      border-bottom: 1px solid $table-border-color;
      font-size: $font-size-xs;
      display: flex;

      &:last-of-type {
         border-bottom: none;
      }

      .checkbox {
         flex: none;
         align-self: center;
         margin-right: .25rem;
      }

      .content {
         flex: 1;

         .flex {
            margin-top: .1875rem;
         }

         p {
            font-size: $font-size-xs;
            color: $light-black;

            &.sender-email {
               color: $primary;
            }

            &.sender-name {
               margin-right: .5rem;
            }

            &.sender-phone {
               opacity: 0.7;
               // margin-left: auto;
            }
         }
      }
   }
}

.reserv-wrap {

   div.col-md-6 {
      padding: .5rem;
   }
}

/*
==========================================================================
   Style :: Magazine/Event
==========================================================================
*/

img.mgz-thumb {
   width: auto;
   max-width: 180px;
   height: 40px;
   margin: 0;
   object-fit: cover;
}

img.mgz-detail-img {
   width: 100%;
   height: auto;
   max-height: 400px;
   object-fit: cover;
}

img.mgz-upload-thumb {
   width: 20rem;
   height: auto;
   display: block;
   margin-top: .5rem;

   &:not([src]) {
      margin-top: 0;
   }
}

.movie-container {
   position: relative;
   padding-bottom: 56.25%;
   height: 0;
   overflow: hidden;
   max-width: 100%;

   iframe, object, embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
   }
}

/*
==========================================================================
   Style :: 1:1
==========================================================================
*/

.attach-wrap {
   display: flex;
   flex-wrap: wrap;

   .attach-item {
      margin: .0625rem;
      background-color: $darkgray;
      color: #fff;
      font-size: $font-size-xs;
      font-weight: 700;
      padding: .125rem .25rem;

      &:hover {
         color: #fff;
         background-color: $darkgray-hover;
      }
   }
}

/*
==========================================================================
   Style :: partner
==========================================================================
*/

.partner-logo-thumb {
   width: 100px;
   height: auto;
   max-height: 150px;
}

.partner-logo-detail {
   width: 180px;
   height: auto;
   max-height: 360px;
   display: block;
   margin-left: auto;
   margin-right: auto;
}