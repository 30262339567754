/*
==========================================================================
   Login
==========================================================================
*/

.login-bg {
    background-color: $dark;
}

.login-box {
    width: 14rem;

    .titlebox {
        flex-direction: column;
        @include flex-middle;
        margin-bottom: 0.5rem;    

        &::before {
            content: '';
            display: block;
            width: 101px;
            height: 33.5px;
            background-image: url('/img_logo.png');
            background-size: 100%;
            margin-bottom: 1rem;
        }

        .title {
            color: $full-white;
            font-size: 1.25rem;
            font-weight: 300;
        }
    }

    .input-wrap {
        padding: 0.25rem 0;

        >input {
            padding-top: 0.1rem;
            padding-bottom: 0.1rem;
            height: 38px;
            border-radius: 8px;
            color: $black;
            font-size: $font-size;

            &:focus {
                background-color: $full-white;
                border-color: $primary;
            }

            &[type="password"] {
                font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
            }
        }
    }

    .btn-login {
        margin-top: .5rem;
        background-color: $primary;
        border-radius: 8px;
        padding-top: .25rem;
        padding-bottom: .25rem;
        cursor: pointer;
        transition: background 0.15s ease-in-out;

        &:hover {
            background-color: $primary-hover;
        }

        >.btn-text {
            font-size: $font-size;
            font-weight: 900;
        }
    }
}