/*
==========================================================================
   Header
==========================================================================
*/

.navbar {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.03), 0 1px 0 rgba(0, 0, 0, 0.03);
    min-height: $navbar-height;
    width: inherit;
    top: 0;
    right: 0;
    left: 0;
    margin: inherit;
    display: block;
    z-index: 500;

    .navbar-menu-wrapper {
        width: auto;
        height: $navbar-height;
        flex: 1;
        padding: 0 $spacer;
        @include flex-hor;
        color: $black;
        justify-content: unset !important;

        .navbar-nav .nav-item .dropdown .count-indicator {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: url('/img_admin_face.png') center/100% no-repeat
        }

        .navbar-nav .nav-item.nav-profile {
            margin-right: .5rem;
            color: $light-black;
        }
    }

    .titlebox {
        line-height: 1;
        span {
            font-size: 0.6rem;
            color: $primary;
            font-weight: 700;
        }

        h1 {
            font-size: 1.15rem;
            margin-top: 0.2rem;
            margin-bottom: 0.35rem;
            color: $dark;
            font-weight: 900;
            line-height: 1;
        }
    }
}
