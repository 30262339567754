.ReactTable {
    .rt-tbody {
        min-width: 800px;

        .rt-td {
            flex: 100 0 auto;
            width: 100px;
        }
    }
}

.editor-container {
    width: 100%;
    height: 400px;
    background-color: lightblue;
    font-size: 2rem;
    @include flex-middle;
}

.ck-editor__editable_inline {
    height: 300px !important;
    line-height: 0.2;
}

.list-contents .checkbox .checkbox-label input[type=checkbox] {
	display: none;
}

.sorting-row {
    display: table;
}