/*
==========================================================================
    Common
==========================================================================
*/

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /*ie8이상*/
}

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    font: {
        family: $font-family-serif;
        weight: 400;
    }
    color: $full-black;
    -webkit-font-smoothing: antialiased;
}

html {
    font-size: 16px;
}

body {
    -webkit-overflow-scrolling: touch;
    background-color: $body-bg;
}

input,
button,
textarea,
select,
a {
    font-family: inherit;
    color: inherit;
    outline: none;
    -webkit-tap-highlight-color: transparent;
}

ul,
ol {
    list-style: none;
}

a,
button {
    text-decoration: none;
    transition: all 0.1s ease-in-out;
}

a:hover,
button:hover,
a:active,
button:active {
    transition: all 0.1s ease-in-out;
    text-decoration: none;
}

a:active {
    color: inherit;
}

h1 {
    font-size: 18px;
    font-weight: 700;
}

h3 {
    font-size: 18px;
    font-weight: 400;
}

h4 {
    font-size: 14px;
    font-weight: 700;
}

/*모바일 스타일 제거*/
input,
textarea,
button {
    -webkit-appearance: none;
    appearance: none;
}

.h1, .h2, .h3, .h4, .h5, .h6, body, h1, h2, h3, h4, h5, h6 {
    family: $font-family-serif;
}